/**
 * Main project app script!
 *
 * All script functionality should be in this file.
 *
 * NOTE: jQuery by default is NOT supported! Please make sure to follow ES6 paradigms and create vanilla JS
 * wherever possible. jQuery is included for the sake of plugins that we rely on such as Gravity Forms.
 * Refer to the document at https://wiki.posturedev.com for further information.
 **/

//Modules
// import { Glightbox, AOS, Swiper } from "Parent/js/modules/modules";
// import * as AOS from "aos/dist/aos.js";
// import "aos/dist/aos.css";

// All generic global site javascript should live in this file.
import Globals from "./globals";

// Attach the scripts after the website is up and running
document.addEventListener("DOMContentLoaded", () => {
	// Remove and uncomment the enqueue script in functions.php if not needed
	window.$ = jQuery();

	const globals = new Globals();

	var rellax = new Rellax(".rellax");
	AOS.init();

	menuToggle();

	jQuery(".form-select-input").on("change", function () {
		var selected = jQuery(this).find("option:selected").val();
		selected != "" ? jQuery(this).addClass("selected") : jQuery(this).removeClass("selected");
	});
});

const menuToggle = () => {
	const burger = document.querySelector(".burger");
	const menu = document.querySelector(".header-menu");
	const hero = document.querySelector(".hero");

	burger.addEventListener("click", () => {
		// menu.classList.add("active");

		// check if active to toggle menu
		if (burger.classList.contains("active")) {
			burger.classList.remove("active");
			menu.classList.remove("active");
			hero.classList.remove("burger-menu-active");
		} else {
			burger.classList.add("active");
			menu.classList.add("active");
			hero.classList.add("burger-menu-active");
		}
	});

	jQuery(".header-menu a").on("click", function () {
		console.log("clicked");
		burger.classList.remove("active");
		menu.classList.remove("active");
		hero.classList.remove("burger-menu-active");
	});
};
